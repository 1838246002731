import React, { useState } from 'react'
import {useForm} from 'react-hook-form'

export default function App() {
  const GATEWAY_URL = "https://bojkzqlxfb.execute-api.ap-east-1.amazonaws.com/prod";
  const required = "This field is required";
  const [ submitted, setSubmitted ] = useState(false);
  const { 
    register, 
    handleSubmit, 
    setError, 
    errors ,
    reset,
    formState: { isSubmitting }
  } = useForm();
  
  const onSubmit = data => { 
    try {
      fetch(GATEWAY_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      setSubmitted(true);
      reset();
    } catch (error) {
      // handle server errors
      setError(
        "submit",
        "submitError",
        "Sorry there's been an issue on submitting the form! Please try again or email us at hellow@empathystrategy.com"
      )
    }
  };

  const showSubmitError = msg => <p className="error">{msg}</p>;

  const showThankYou = (
    <div className="thankyou">
      <p>Thank you for contacting us! We will reach out to you shortly.</p>
      <button type="button" onClick={() => setSubmitted(false)} class="btn btn-default">
        Send another message?
      </button>
    </div>
  );

  const showForm = (
    <form name="Contact Form" method="POST" onSubmit={handleSubmit(onSubmit)}>
      <div class="position-relative form-group">
        <label for="name">Name *</label>
        <input 
          type="text" 
          name="name" 
          id="name" 
          placeholder="Name" 
          class="form-control" 
          ref={register({ required })}
          disabled={isSubmitting}
        />
        {errors.name && <div className="error">{errors.name.message}</div>}
      </div>
      <div class="position-relative form-group">
        <label for="email">Email *</label>
        <input 
          type="text" 
          name="email" 
          id="email" 
          placeholder="Email" 
          class="form-control" 
          ref={register({ required })}
          disabled={isSubmitting}
        />
        {errors.email && <div className="error">{errors.email.message}</div>}
      </div>
      <div class="position-relative form-group">
        <label for="phone">Phone</label>
        <input 
          type="text" 
          name="phone" 
          id="phone" 
          placeholder="Phone" 
          class="form-control" 
          ref={register}
          disabled={isSubmitting}
        />
      </div>
      <div class="position-relative form-group">
        <label for="comments">Message</label>
        <textarea 
          type="text" 
          name="comments" 
          id="comments" 
          placeholder="Message" 
          class="form-control" 
          ref={register}
          disabled={isSubmitting}
        />
      </div>
      <input type="submit" value="Submit" class="btn btn-default"/>
    </form>
  );

  return (
    <div class="contact-us">{ submitted ? showThankYou : showForm }</div>
  );
}